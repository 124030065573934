import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Kamalshcontentpage from "../components/subpages/kamalashcontent";

import Kamalashibreadcreumb from "../components/subpages/kamalashbreacumb";

// markup
const kamalashPage = () => {
  return (
    <div>
      <Seo title="Kamalesh-d-patel" />

      <Layout isSticky>
        <section>
          <Kamalashibreadcreumb />
        </section>

        <section>
          <Kamalshcontentpage />
        </section>
      </Layout>
    </div>
  );
};

export default kamalashPage;
