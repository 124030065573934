import React from "react";
import { Link } from "gatsby";

export default function Kamalshcontentpage() {
  return (
    <section className="kamalshcontentwrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className="theme-h4 theme-color"> Kamlesh D Patel </h4>
            <p className="theme-p-tag"> (1956 – Present) </p>
            <p className="theme-p-tag">
              {" "}
              Kamlesh D. Patel is an original voice in an ancient tradition.
              Known to many as Daaji, his teachings on Heartfulness Meditation
              arise from his personal experience, while reflecting his deep
              spirit of enquiry and respect for the world’s great spiritual
              traditions and scientific advancements.
            </p>
            <p className="theme-p-tag">
              {" "}
              Born in Gujarat in 1956, Daaji displayed an early interest in
              meditation and spirituality. He started Heartfulness Meditation at
              the age of nineteen, while studying to be a pharmacist. Soon
              after, he met his first Guru, Babuji, the second guide in the
              Heartfulness lineage. After graduating with honours from L.M.
              College of Pharmacy in Ahmedabad, Daaji married and moved to New
              York City. There, he and his wife raised their two sons, while he
              steadily built a successful pharmacy business. At the same time,
              Daaji continued to pursue his love of meditation as a devoted
              disciple of Babuji’s successor, Chariji. Over the years, Daaji
              played an increasingly active role in the Heartfulness Movement,
              both organisationally and by spreading its message and teaching
              its method. In 2011, Chariji selected Daaji as his spiritual
              successor.
            </p>
            <p className="theme-p-tag">
              {" "}
              As the leader of the Heartfulness Movement, Daaji now fulfils the
              many duties of a modern-day guru, travelling widely and extending
              his support to seekers everywhere. Daajifirmly believes in
              nourishing today’s youth with practical self-management tools and
              universal values. Under his guidance, students and faculty in over
              2,500 schools, universities, and colleges are now benefitting from
              a portfolio of value-based self-development programs.
            </p>
            <p className="theme-p-tag">
              {" "}
              As a self-professed student of spirituality, Daaji devotes much of
              his time and energy to his personal research in the field of
              spirituality and consciousness, and regularly shares his findings
              through public speaking engagements, his website and social media
              platforms, and in broadcast, print, and digital media. Daaji’s
              articles have appeared in various leading publications including
              Huffington Post, Chicago Tribune, Times of India, and Business
              Standard. His well-received Radio City Swaran series, Dil Ki
              Awaaz, ran for twelve episodes.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              Daaji advocates bridging ancient tradition with modern scientific
              understanding, and has assembled a team of one hundred scientists
              to research the physiological and genetic effects of meditation
              and yogic transmission. He believes in approaching spirituality
              with scientific methodology – a practical approach stemming from
              his own experience and mastery in the field. As he often says,
              “You are the experimenter, the experiment, and also its result.”
            </p>
            <p className="theme-p-tag">
              {" "}
              Daaji’swish is for Heartfulness Meditation to be available to
              every household around the world. Under his guidance, Heartfulness
              organisations offer free meditation training at thousands
              ofHeartspots and Retreat Centres in 130 countries, and
              Heartfulness trainers are available worldwide for individual and
              group meditation in person or remotely via the Let’s Meditate app
              for iPhone and Android.
            </p>
            <p className="theme-p-tag">
              {" "}
              To learn more about Daaji, please visit{" "}
              <Link
                to="http://www.daaji.org/"
                target="_blank"
                rel="noreferrer"
                className="text-decore-none"
              >
                <span className="orange-text"> http://www.daaji.org/ </span>
              </Link>{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
